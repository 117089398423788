(function ($) {

  /**
   * The recommended way for producing HTML markup through JavaScript is to write
   * theming functions. These are similiar to the theming functions that you might
   * know from 'phptemplate' (the default PHP templating engine used by most
   * Drupal themes including Omega). JavaScript theme functions accept arguments
   * and can be overriden by sub-themes.
   *
   * In most cases, there is no good reason to NOT wrap your markup producing
   * JavaScript in a theme function.
   */
  Drupal.theme.prototype.wscExampleButton = function (path, title) {
    // Create an anchor element with jQuery.
    return $('<a href="' + path + '" title="' + title + '">' + title + '</a>');
  };

  /**
   * Behaviors are Drupal's way of applying JavaScript to a page. In short, the
   * advantage of Behaviors over a simple 'document.ready()' lies in how it
   * interacts with content loaded through Ajax. Opposed to the
   * 'document.ready()' event which is only fired once when the page is
   * initially loaded, behaviors get re-executed whenever something is added to
   * the page through Ajax.
   *
   * You can attach as many behaviors as you wish. In fact, instead of overloading
   * a single behavior with multiple, completely unrelated tasks you should create
   * a separate behavior for every separate task.
   *
   * In most cases, there is no good reason to NOT wrap your JavaScript code in a
   * behavior.
   *
   * @param context
   *   The context for which the behavior is being executed. This is either the
   *   full page or a piece of HTML that was just added through Ajax.
   * @param settings
   *   An array of settings (added through drupal_add_js()). Instead of accessing
   *   Drupal.settings directly you should use this because of potential
   *   modifications made by the Ajax callback that also produced 'context'.
   */
  Drupal.behaviors.wscExampleBehavior = {
    attach: function (context, settings) {
      // By using the 'context' variable we make sure that our code only runs on
      // the relevant HTML. Furthermore, by using jQuery.once() we make sure that
      // we don't run the same piece of code for an HTML snippet that we already
      // processed previously. By using .once('foo') all processed elements will
      // get tagged with a 'foo-processed' class, causing all future invocations
      // of this behavior to ignore them.
      $('.some-selector', context).once('foo', function () {
        // Now, we are invoking the previously declared theme function using two
        // settings as arguments.
        var $anchor = Drupal.theme('wscExampleButton', settings.myExampleLinkPath, settings.myExampleLinkTitle);

        // The anchor is then appended to the current element.
        $anchor.appendTo(this);
      });
    }
  };

  // Replace images or add classes for hi-contrast versions

  Drupal.behaviors.hiconRelatedImages = {
      attach: function (context, settings) {

        if ($('.hicon_version_1, .hicon_version_2', context).length > 0)
        {
          $('.has-icon img, #btn-cool-search-open img', context).addClass('svg-inline');
        }
      }
  }

  // Cool search

  Drupal.behaviors.coolSearch = {
    attach: function (context, settings) {

      var pageContainer = $('.page', context);
      var openCtrl = $('#btn-cool-search-open', context);
      var closeCtrl = $('#btn-cool-search-close button', context);
      var searchContainer = $('#cool-search', context);
      var inputSearch = $('input.custom-search-box', context);
      var opened = -1;

      tabToogle(-1);

      openCtrl.on('click', function(event) {
        event.preventDefault();
        if (opened <= 0) openSearch();
      });

      closeCtrl.on('click', function(event) {
        event.preventDefault();
        closeSearch();
      });

      $(window).on('keyup', function(ev) {
        // escape key
        if ( ev.keyCode == 27 ) {
          closeSearch();
        }
      });

      function tabToogle(how) {
        searchContainer.find('input, button, a').each(function () {

          $(this).attr('tabindex', how);
        });
      }

      function openSearch() {
        searchContainer.addClass('cool-search--open');
        inputSearch.focus();

        setTimeout(function() {opened = 1;}, 1);

        tabToogle(0);

        $('body').click(function(e) {

            if ($(e.target).closest('#cool-search').length === 0 && opened == 1) {
              closeSearch();
            }
        });
      }

      function closeSearch() {
        searchContainer.removeClass('cool-search--open');
        inputSearch.blur();
        inputSearch.value = '';
        opened = 0;

        tabToogle(-1);

        $('body').off('click');
      }
    }
  }

  // Side grippie

  Drupal.behaviors.sideGrippie = {
    attach: function (context, settings) {
      
      var sg = $('#side-grippie');
      var sg_links = $('#side-grippie__wrapper a');
      
      sg.hover(function() {
        $(this).addClass('pulled');
      }, function() {
        $(this).removeClass('pulled');
      });
      
      sg_links.focus(function() {
        sg.addClass('pulled');
      });
      
      sg_links.blur(function() {
        sg.removeClass('pulled');
      });
    }
  }

  // Collapsible content

  Drupal.behaviors.collapsibleContent = {
    attach: function (context, settings) {

      var ct = $('.collapsible_content__inner', context);
      var btn = $('.enlarge_button', context);
      var arrow = $('.enlarge_button div', context);

      var windowWidth = $(window).width();

      $(window).on('resize', function() {

        if ($(window).width() != windowWidth) {

          windowWidth = $(window).width();

          // reset on resize
          
          if (viewport() >= 560)
          {
            ct.css('height', 'auto');
            ct.removeClass('visible');
          }
          else
          {
            ct.css('height', '7em');
            ct.removeClass('visible');
            btn.removeClass('active');
            arrow.removeClass('rotated').addClass('normal');
          }
        }
      });
      
      arrow.click(function() {
           
        if (!ct.hasClass('visible'))
        {
          var ct_height = ct.height();
          ct.css('height', 'auto');
          var auto_height = ct.height();
          
          ct.height(ct_height).animate({height: auto_height}, 400, function() {
            btn.addClass('active');
          });

          ct.addClass('visible');
          
          arrow.removeClass('normal').addClass('rotated');
        }
        else
        {
          var ct_height = ct.height();
          btn.removeClass('active');

          ct.height(ct_height).animate({height: '7.5em'}, 400);
          ct.removeClass('visible');
          
          arrow.removeClass('rotated').addClass('normal');
        }
      });
    }
  }

  function viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return e[ a+'Width' ];
  }

  // Jump to the first available letter in the dictionary

  Drupal.behaviors.dictionaryLetterJump = {
    attach: function (context, settings) {
      
      if ($('.view-slownik-pojec').length > 0)
      {
        if (get_url_vars('title') != '' || (get_url_vars('field_term_tematyka_tid') != '' && get_url_vars('field_term_tematyka_tid') != 'All'))
        {
          var letter_array = [];

          $('.view-slownik-pojec .views-summary li').each(function() {
            letter_array.push($(this).find('a').text().toLowerCase());
          })

          if (letter_array.length > 0)
          {
            var first_letter = letter_array[0];

            var url = decodeURI(window.location.href);

            var url_letter = url.substring(url.indexOf('?') - 1, url.indexOf('?'));

            var has_slash = url.includes('slownik-pojec/');

            if (has_slash == true && !letter_array.includes(url_letter))
            {
              window.location.replace(url.replace('slownik-pojec/' + url_letter, 'slownik-pojec/' + first_letter));
            }
            else if (has_slash == false)
            {
              window.location.replace(url.replace('slownik-pojec', 'slownik-pojec/' + first_letter));
            }
          }
        }
      }
    }
  }

  // Get vars from URL helper

  function get_url_vars(variable)
  {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    
    for (var i=0; i<vars.length; i++)
    {
      var pair = vars[i].split("=");
      
      if (pair[0] == variable)
      {
        return pair[1];
      }
    }
    
    return(false);
  }

  // Custom accordion in nodes

  Drupal.behaviors.customAccordionLinks = {
    attach: function (context, settings) {

      var link = '<a class="accordion-title__link" href="#" aria-expanded="false"></a>';

      $('h2.accordion-title, h3.accordion-title, .views-field.accordion-title .field-content', context).wrapInner(link);

      $('.list-accordion-title', context).each(function() {
          $(this).contents().eq(0).wrap(link);
      });

      $('.accordion-title__link', context).on('click', function(event) {
        event.preventDefault();
      });
    }
  }

  Drupal.behaviors.customAccordion = {
    attach: function (context, settings) {

      // remove functionality from check-lists

      if ($('.view-check-listy', context).length > 0)
      {
         $('h2.accordion-title, h3.accordion-title').removeClass('accordion-title');
         $('a.accordion-title__link').contents().unwrap();
      }

      // open saved accordions on load

      var page = $('article').attr('about');
      var op_ac = $.session.get(page);

      var list_page = 'list_' + $('article').attr('about');
      var list_op_ac = $.session.get(list_page);

      if (typeof op_ac !== 'undefined')
      {
        var index;
        var op_ac = op_ac.split(',');

        for (index = 0; index < op_ac.length; ++index) {
          $('.accordion-title', context).eq(op_ac[index] - 1).addClass('accordion-title--opened accordion-title--opened--noline');
          $('.accordion', context).eq(op_ac[index] - 1).show();
        }
      }

      if (typeof list_op_ac !== 'undefined')
      {
        var list_index;
        var list_op_ac = list_op_ac.split(',');

        for (list_index = 0; list_index < list_op_ac.length; ++list_index) {
          $('.list-accordion-title', context).eq(list_op_ac[list_index] - 1).addClass('list-accordion-title--opened');
          $('.list-accordion', context).eq(list_op_ac[list_index] - 1).show();
        }
      }

      // open and save opened accordions on click
      
      $('main').once('customAccordion').on('click', '.accordion-title, .list-accordion-title > a', function() {

        if ($(this).is('.accordion-title'))
        {
          if ($(this).next().is(":hidden"))
          {
            $(this).next().slideDown();
            $(this).addClass('accordion-title--opened accordion-title--opened--noline');
            $(this).find('a').attr('aria-expanded', 'true');
          }
          else
          {
            $(this).next().slideUp(function() {
              $(this).prev().removeClass('accordion-title--opened--noline');
            });
            $(this).removeClass('accordion-title--opened');
            $(this).find('a').attr('aria-expanded', 'false');
          }
        }
        else if ($(this).is('.list-accordion-title > a'))
        {
          if ($(this).siblings('.list-accordion').is(":hidden"))
          {
            $(this).siblings('.list-accordion').slideDown();
            $(this).parent().addClass('list-accordion-title--opened');
            $(this).find('a').attr('aria-expanded', 'true');
          }
          else
          {
            $(this).siblings('.list-accordion').slideUp();
            $(this).parent().removeClass('list-accordion-title--opened');
            $(this).find('a').attr('aria-expanded', 'false');
          }
        }
        
        var accordions_no = [];
        var list_accordions_no = [];

        $('.node .accordion-title--opened').each(function() {

          accordions_no.push($(this).index('.accordion-title') + 1);
        });

        $('.node .list-accordion-title--opened').each(function() {

          list_accordions_no.push($(this).index('.list-accordion-title') + 1);
        });

        // save only accordions in nodes

        if ($(this).parents('.node').length > 0)
        {
          $.session.set(page, accordions_no.join(','));
          $.session.set('list_' + page, list_accordions_no.join(','));
        }
      });
    }
  }

  // Accordion for webform RODO stuff

  Drupal.behaviors.webformRodoAccordion = {
    attach: function (context, settings) {

      var legend = $('.webform-component--zgoda-na-przetwarzanie-danych-osobowych .webform-component-fieldset legend', context);

      var fieldset = $('.webform-component--zgoda-na-przetwarzanie-danych-osobowych .webform-component-fieldset .fieldset-wrapper', context);

      var link = '<a class="rodo-title__link" href="#" aria-expanded="false"></a>';

      legend.wrapInner(link);

      $('.rodo-title__link', context).on('click', function(event) {
        event.preventDefault();
      });

      legend.on('click', function() {

        if (fieldset.is(":hidden"))
        {
          $(this).siblings('.fieldset-wrapper').slideDown();
          $(this).addClass('accordion-title--opened');
          $(this).find('a').attr('aria-expanded', 'true');
        }
        else
        {
          $(this).siblings('.fieldset-wrapper').slideUp();
          $(this).removeClass('accordion-title--opened');
          $(this).find('a').attr('aria-expanded', 'false');
        }
      });
    }
  }

  // Smooth scroll
    
  Drupal.behaviors.smoothScroll = {
    attach: function (context, settings) {
      
      $('.node .field--name-body a[href*="#"]:not(.skip-link):not(.accordion-title__link)').on('click', function(event) {
      
        var target = $(this).attr('href');
      
        if (target.length > 0)
        {
          target = target.substr(target.indexOf('#'));
          
          // preventDefault only if the anchor is present on page
          if ($('.node ' + target).length > 0) event.preventDefault();
          
          $('html, body').animate({
            scrollTop: $(target).offset().top
          }, 800, function() {
            window.location.hash = target;
          });
        }
      });
    }
  }

  // Replace img with inline svg
    
  Drupal.behaviors.inlineSVG = {
    attach: function (context, settings) {

      $('img.svg-inline').each(function() {
        var $img = jQuery(this);
        var imgURL = $img.attr('src');
        var attributes = $img.prop("attributes");

        $.get(imgURL, function(data) {
          // Get the SVG tag, ignore the rest
          var $svg = jQuery(data).find('svg');

          // Remove any invalid XML tags
          $svg = $svg.removeAttr('xmlns:a');

          // Loop through IMG attributes and apply on SVG
          $.each(attributes, function() {
            $svg.attr(this.name, this.value);
          });

          // Replace IMG with SVG
          $img.replaceWith($svg);
        }, 'xml');
      });
    }
  }

  // Replace search icon with svg
  
  Drupal.behaviors.svgSearchIcon = {
    attach: function (context, settings) {
    
      // Replace the search icon with PNG image on IE8
      if ($('.lt-ie9 .search-block-form .form-submit', context).length > 0)
      {
        icon_attr = $('.lt-ie9 .search-block-form .form-submit', context).attr('src');
        icon_attr = icon_attr.replace('svg', 'png');
        
        $('.lt-ie9 .search-block-form .form-submit', context).attr('src', icon_attr);
      }
    }
  }

  // Report error

  Drupal.behaviors.reportError = {
    attach: function (context, settings) {

      $('a.report-error', context).on('click', function(event) {

        event.preventDefault();

        var block = $('#block-webform-client-block-1009');

        if (!block.is(':visible'))
        {
          block.slideDown();
          $(this).attr('aria-expanded', 'true');
        }
        else
        {
          if ($(this).hasClass('report-error'))
          {
            block.slideUp();
            $(this).attr('aria-expanded', 'false');
          }
        }
      });

      // clear page url field if displayed as node

      if ($('body').hasClass('node-type-webform'))
      {
        $('#edit-submitted-grupa-strona-na-ktorej-wystepuje-problem').val('');
      }
    }
  }

  Drupal.behaviors.leaveFeedback = {
    attach: function (context, settings) {

      $('#rate-button-2, #rate-button-3', context).on('click', function(event) {

        event.preventDefault();

        var block = $('#block-webform-client-block-4452');

        setTimeout(() => {

          $('html, body').animate({
            scrollTop: $('#leave-feedback').offset().top
          }, 800);

        }, 500);

        if (!block.is(':visible'))
        {
          block.slideDown();
          $(this).attr('aria-expanded', 'true');
        }
        else
        {
          if ($(this).hasClass('report-error'))
          {
            block.slideUp();
            $(this).attr('aria-expanded', 'false');
          }
        }
      });

      // clear page url field if displayed as node

      if ($('body').hasClass('node-type-webform'))
      {
        $('#edit-submitted-grupa-strona').val('');
      }
    }
  }

  // a11y field desciptions with aria

  Drupal.behaviors.a11yDesciptions = {

    attach: function (context, settings) {

      // fieldsets

      $('.webform-client-form .fieldset-description', context).each(function(index) {
        
        $(this).attr('id', 'fieldset-description-fs-' + index);
        $(this).parents('.webform-component-fieldset').attr('aria-describedby', $(this).attr('id'));
      });

      // normal input fields

      $('.webform-client-form .description', context).each(function(index) {
        
        $(this).attr('id', 'description-nif-' + index);
        $(this).siblings('input').attr('aria-describedby', $(this).attr('id'));
      });

      // normal textarea fields

      $('.webform-client-form .webform-component-textarea .description', context).each(function(index) {
        
        $(this).attr('id', 'description-ntf-' + index);
        $(this).siblings('.form-textarea-wrapper').find('textarea').attr('aria-describedby', $(this).attr('id'));
      });

      // a11y datepicker fields

      $('.webform-client-form .webform-component-advdate .description', context).each(function(index) {
        
        $(this).attr('id', 'description-dp-' + index);
        $(this).siblings('.webform-container-inline').find('input').attr('aria-describedby', $(this).attr('id'));
      });

      // exposed forms with BEF descriptions

      $('.views-exposed-widgets .form-item .description', context).each(function(index) {
        
        $(this).attr('id', 'description-efb-' + index);
        $(this).siblings('input').attr('aria-describedby', $(this).attr('id'));
      });

      // exposed forms with normal descriptions

      $('.views-exposed-widget .description', context).each(function(index) {
        
        $(this).attr('id', 'description-efn-' + index);
        $(this).siblings('.views-widget').find('input').attr('aria-describedby', $(this).attr('id'));
      });
    }
  };

  // Masked input

  Drupal.behaviors.maskedInput = {
    attach: function (context, settings) {
      
      $('.webform-component-advdate .form-text', context).mask("00-00-0000");

      var lang = $('html').attr('lang');

      if (lang == 'en') var put_text = 'dashes will be added automatically';
      else if (lang == 'ru') var put_text = 'тире добавляется автоматически';
      else if (lang == 'uk') var put_text = 'тире будуть додані автоматично';
      else var put_text = 'myślniki zostaną dodane automatycznie';

      $('.webform-component-advdate .description', context).append(' (' + put_text + ')');
    }
  }

  // SVG checkboxes and radios

  Drupal.behaviors.svgCheckbox = {
    attach: function (context, settings) {
    
      var checkbxsCheckmark = Array.prototype.slice.call( document.querySelectorAll( '.webform-client-form input[type="checkbox"]' ) ),
        radiobxsCircle = Array.prototype.slice.call( document.querySelectorAll( '.webform-client-form input[type="radio"]' ) ),
        pathDefs = {
          checkmark : ['M16.667,62.167c3.109,5.55,7.217,10.591,10.926,15.75 c2.614,3.636,5.149,7.519,8.161,10.853c-0.046-0.051,1.959,2.414,2.692,2.343c0.895-0.088,6.958-8.511,6.014-7.3 c5.997-7.695,11.68-15.463,16.931-23.696c6.393-10.025,12.235-20.373,18.104-30.707C82.004,24.988,84.802,20.601,87,16'],
          circle : ['M15.833,24.334c2.179-0.443,4.766-3.995,6.545-5.359 c1.76-1.35,4.144-3.732,6.256-4.339c-3.983,3.844-6.504,9.556-10.047,13.827c-2.325,2.802-5.387,6.153-6.068,9.866 c2.081-0.474,4.484-2.502,6.425-3.488c5.708-2.897,11.316-6.804,16.608-10.418c4.812-3.287,11.13-7.53,13.935-12.905 c-0.759,3.059-3.364,6.421-4.943,9.203c-2.728,4.806-6.064,8.417-9.781,12.446c-6.895,7.477-15.107,14.109-20.779,22.608 c3.515-0.784,7.103-2.996,10.263-4.628c6.455-3.335,12.235-8.381,17.684-13.15c5.495-4.81,10.848-9.68,15.866-14.988 c1.905-2.016,4.178-4.42,5.556-6.838c0.051,1.256-0.604,2.542-1.03,3.672c-1.424,3.767-3.011,7.432-4.723,11.076 c-2.772,5.904-6.312,11.342-9.921,16.763c-3.167,4.757-7.082,8.94-10.854,13.205c-2.456,2.777-4.876,5.977-7.627,8.448 c9.341-7.52,18.965-14.629,27.924-22.656c4.995-4.474,9.557-9.075,13.586-14.446c1.443-1.924,2.427-4.939,3.74-6.56 c-0.446,3.322-2.183,6.878-3.312,10.032c-2.261,6.309-5.352,12.53-8.418,18.482c-3.46,6.719-8.134,12.698-11.954,19.203 c-0.725,1.234-1.833,2.451-2.265,3.77c2.347-0.48,4.812-3.199,7.028-4.286c4.144-2.033,7.787-4.938,11.184-8.072 c3.142-2.9,5.344-6.758,7.925-10.141c1.483-1.944,3.306-4.056,4.341-6.283c0.041,1.102-0.507,2.345-0.876,3.388 c-1.456,4.114-3.369,8.184-5.059,12.212c-1.503,3.583-3.421,7.001-5.277,10.411c-0.967,1.775-2.471,3.528-3.287,5.298 c2.49-1.163,5.229-3.906,7.212-5.828c2.094-2.028,5.027-4.716,6.33-7.335c-0.256,1.47-2.07,3.577-3.02,4.809']
        },
        animDefs = {
          checkmark : { speed : .2, easing : 'ease-in-out' },
          circle : { speed : .2, easing : 'ease-in-out' },
        };

      function createSVGEl( def ) {
        var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        if( def ) {
          svg.setAttributeNS( null, 'viewBox', def.viewBox );
          svg.setAttributeNS( null, 'preserveAspectRatio', def.preserveAspectRatio );
        }
        else {
          svg.setAttributeNS( null, 'viewBox', '0 0 100 100' );
        }
        svg.setAttribute( 'xmlns', 'http://www.w3.org/2000/svg' );
        return svg;
      }

      function controlCheckbox(el, type, svgDef) {
        var svg = createSVGEl(svgDef);
        el.parentNode.appendChild(svg);

        if (el.checked) {
          draw(el, type);
        }

        el.addEventListener('change', function() {
          if (el.checked) {
            draw(el, type);
          } else {
            reset(el);
          }
        });
      }

      function controlRadiobox( el, type ) {
        var svg = createSVGEl();
        el.parentNode.appendChild( svg );

        if (el.checked) {
          draw(el, type);
        }

        el.addEventListener('change', function() {
          resetRadio( el );
          draw( el, type );
        });
      }

      checkbxsCheckmark.forEach( function( el, i ) { controlCheckbox( el, 'checkmark' ); } );
      radiobxsCircle.forEach( function( el, i ) { controlRadiobox( el, 'circle' ); } );

      function draw( el, type ) {
        var paths = [], pathDef, 
          animDef,
          svg = el.parentNode.querySelector( 'svg' );

        switch( type ) {
          case 'checkmark': pathDef = pathDefs.checkmark; animDef = animDefs.checkmark; break;
          case 'circle': pathDef = pathDefs.circle; animDef = animDefs.circle; break;
        };
        
        paths.push( document.createElementNS('http://www.w3.org/2000/svg', 'path' ) );

        if( type === 'cross' || type === 'list' ) {
          paths.push( document.createElementNS('http://www.w3.org/2000/svg', 'path' ) );
        }
        
        for( var i = 0, len = paths.length; i < len; ++i ) {
          var path = paths[i];
          svg.appendChild( path );

          path.setAttributeNS( null, 'd', pathDef[i] );

          var length = path.getTotalLength();
          // Clear any previous transition
          //path.style.transition = path.style.WebkitTransition = path.style.MozTransition = 'none';
          // Set up the starting positions
          path.style.strokeDasharray = length + ' ' + length;
          if( i === 0 ) {
            path.style.strokeDashoffset = Math.floor( length ) - 1;
          }
          else path.style.strokeDashoffset = length;
          // Trigger a layout so styles are calculated & the browser
          // picks up the starting position before animating
          path.getBoundingClientRect();
          // Define our transition
          path.style.transition = path.style.WebkitTransition = path.style.MozTransition  = 'stroke-dashoffset ' + animDef.speed + 's ' + animDef.easing + ' ' + i * animDef.speed + 's';
          // Go!
          path.style.strokeDashoffset = '0';
        }
      }

      function reset( el ) {
        Array.prototype.slice.call( el.parentNode.querySelectorAll( 'svg > path' ) ).forEach( function( el ) { el.parentNode.removeChild( el ); } );
      }

      function resetRadio( el ) {
        Array.prototype.slice.call( document.querySelectorAll( 'input[type="radio"][name="' + el.getAttribute( 'name' ) + '"]' ) ).forEach( function( el ) { 
          var path = el.parentNode.querySelector( 'svg > path' );
          if( path ) {
            path.parentNode.removeChild( path );
          }
        } );
      }
    }
  };

  // Nice links

  Drupal.behaviors.niceLinks = {
    attach: function (context, settings) {
      
      $('span.arrowed a', context).append('<span class="link-arrow"></span>');
      $('span.pay-online a', context).prepend('<span class="link-pay-online"></span>');
    }
  }

  // Shorten language links in node links

  Drupal.behaviors.shortenLanguageLinks = {
    attach: function (context, settings) {

      languageLinks();
      
      var windowWidth = $(window).width();

      $(window).on('resize', function() {

        if ($(window).width() != windowWidth) {

          windowWidth = $(window).width();

          languageLinks();
        }
      });

      function languageLinks() {

        if (viewport() >= 600)
        {
          $('.node__links .translation_pl a', context).text('Polski');
          $('.node__links .translation_en a', context).text('English');
          $('.node__links .translation_ru a', context).text('Русский');
          $('.node__links .translation_uk a', context).text('Українська');
        }
        else
        {
          $('.node__links .translation_pl a', context).text('PL');
          $('.node__links .translation_en a', context).text('EN');
          $('.node__links .translation_ru a', context).text('RU');
          $('.node__links .translation_uk a', context).text('UA');
        }
      }
    }
  }

  // Responsive menu hamburger

  Drupal.behaviors.menuHamburger = {
    attach: function (context, settings) {
      
      $('.responsive-menus .toggler', context).html('<img src="https://migrant.poznan.uw.gov.pl/sites/all/themes/wsc/images/rm_menu.svg" alt=""> Menu');
    }
  }

  // Adjust webform date selects accordingly to date textfield

  // Drupal.behaviors.adjustDateSelects = {
  //   attach: function (context, settings) {

  //     // make sure textfields has required attr

  //     $('.date-format-pl', context).each(function() {

  //       var isReq = $(this).siblings('div').find('.day').attr('required');

  //       if (isReq == 'required') $(this).attr('required', 'required');
  //     });

  //     // adjust date selects
      
  //     $('.date-format-pl', context).change(function() {

  //       var dateField = $(this);
  //       var dateString = dateField.val();
        
  //       if (dateString.match(/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/))
  //       {
  //         var dateSplitted = dateString.split('-');

  //         var day = (dateSplitted[0].charAt(0) === '0') ? dateSplitted[0].substr(1) : dateSplitted[0];
  //         var month = (dateSplitted[1].charAt(0) === '0') ? dateSplitted[1].substr(1) : dateSplitted[1];
  //         var year = dateSplitted[2];

  //         var startDateUS = new Date(dateField.next().attr('class').substr(dateField.next().attr('class').indexOf('webform-calendar-start') + 23, 10));
  //         var endDateUS = new Date(dateField.next().attr('class').substr(dateField.next().attr('class').indexOf('webform-calendar-end') + 21, 10));

  //         var startDateSplitted = dateField.next().attr('class').substr(dateField.next().attr('class').indexOf('webform-calendar-start') + 23, 10).split('-');

  //         var dateStringUS = new Date(year + '-' + month + '-' + day);

  //         if (dateStringUS >= startDateUS && dateStringUS <= endDateUS)
  //         {
  //           $(this).siblings('div').find('.day').val(day);
  //           $(this).siblings('div').find('.month').val(month);
  //           $(this).siblings('div').find('.year').val(year);
  //         }
  //         else
  //         {
  //           $(this).siblings('div').find('.day').val(startDateSplitted[2]);
  //           $(this).siblings('div').find('.month').val(startDateSplitted[1]);
  //           $(this).siblings('div').find('.year').val(startDateSplitted[0]);
  //         }
  //       }
  //     });
  //   }
  // }

  // Adjust the title of Report error full view page

  Drupal.behaviors.adjustReportErrorTitle = {
    attach: function (context, settings) {
      
      if ($('body', context).hasClass('page-node-1009'))
      {
        var lang = $('html').attr('lang');

        if (lang == 'en') var put_text = 'Report error / Leave Feedback';
        else if (lang == 'ru') var put_text = 'Я сообщаю об ошибке / Просьба дать свои комментарии';
        else if (lang == 'ua') var put_text = 'Виправити помилку / Дайте свої коментарі';
        else var put_text = 'Zgłoś błąd / Przekaż uwagi';

        $('#content > h1', context).text(put_text);
      }

      if ($('body', context).hasClass('page-node-4452'))
      {
        var lang = $('html').attr('lang');

        if (lang == 'en') var put_text = 'Leave Feedback';
        else if (lang == 'ru') var put_text = 'Просьба дать свои комментарии';
        else if (lang == 'ua') var put_text = 'Дайте свої коментарі';
        else var put_text = 'Przekaż uwagi';

        $('#content > h1', context).text(put_text);
      }
    }
  }

  Drupal.behaviors.printCheckLists = {
    attach: function (context, settings) {
      
      $('.print-page', context).click(function(event) {
        event.preventDefault();
        window.print();
      });
    }
  }

})(jQuery);
